/* eslint-disable react/jsx-no-target-blank */
import React, { Suspense } from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { lazy } from '@loadable/component';
import NotepadIcon from '../../Images/notepadIcon.png';
import AwardIcon from '../../Images/awardIcon.png';
import MapIcon from '../../Images/mapIcon.png';
import RatingIcon from '../../Images/ratingIcon.png';
import styles from '../Body/body.module.css';

const Image = lazy(() => import('react-bootstrap/Image'));

export default function WhySunWest() {
    const getSunWestSuccessYears = () => {
        //Sun West founded in 08/12/1980
        const foundedDate = new Date('1980-08-12');
        const todayDate = new Date();    
        const yearDiff = todayDate.getFullYear() - foundedDate.getFullYear();
        const isDate2BeforeBirthday = (
        todayDate.getMonth() < foundedDate.getMonth() ||
        (todayDate.getMonth() === foundedDate.getMonth() && todayDate.getDate() < foundedDate.getDate())
        );
        return isDate2BeforeBirthday ? yearDiff - 1 : yearDiff;
        }
  return (
    <Container fluid={true}>
        <Row
        className={styles.headerRow}
        style={{ justifyContent: 'center' }}
        >
        <div
            className={styles.header_border + ' ' + styles.whysunwestBorder}
        >
            {' '}
        </div>
        <h1 className={styles.whysunwesth2}>Why Sun West</h1>
        </Row>
        <Row
        className={styles.rowTop}
        style={{
            marginRight: '0px',
            marginTop: '20px',
            marginLeft: '0px',
            marginBottom: '20px',
        }}
        >
        <Col xs={12} md={3} sm={6}>
            <a
            href="https://www.sunwestmortgage.com/our-history"
            target="_blank"
            rel="noopener"
            style={{ textDecoration: 'none' }}
            >
            <div className={styles.homePageCards}>
                <div className={styles.imgsec}>
                <Suspense fallback={<div>Loading...</div>}>
                    <Image
                    className={styles.homepageImg}
                    height="auto"
                    alt="Notepad icon"
                    style={{ height: '100%', width: '85px' }}
                    src={NotepadIcon}
                    />
                </Suspense>
                </div>
                <div className={styles.contentsection}>
                <h5>Trusted by families since 1980</h5>
                <p>
                    We&#39;ve been financing the American dream for over {getSunWestSuccessYears()} years.
                   
                </p>
                </div>
            </div>
            </a>
        </Col>

        <Col xs={12} md={3} sm={6}>
            <a
            href="https://www.sunwestmortgage.com/careers"
            target="_blank"
            rel="noopener"
            style={{ textDecoration: 'none' }}
            >
            <div className={styles.homePageCards}>
                <div className={styles.imgsec}>
                <Suspense fallback={<div>Loading...</div>}>
                    <Image
                    className={styles.homepageImg}
                    height="auto"
                    style={{ height: '100%', width: '85px' }}
                    alt="Award icon"
                    src={AwardIcon}
                    />
                </Suspense>
                </div>
                <div className={styles.contentsection}>
                <h5>Award winning culture</h5>
                <p>
                    Our diverse employees are at the heart of what we do, and
                    we care deeply about our customers
                </p>
                </div>
            </div>
            </a>
        </Col>

        <Col xs={12} md={3} sm={6}>
            <a
            href="https://www.sunwestmortgage.com/contact-us"
            target="_blank"
            rel="noopener"
            style={{ textDecoration: 'none' }}
            >
            <div className={styles.homePageCards}>
                <div className={styles.imgsec}>
                <Suspense fallback={<div>Loading...</div>}>
                    <Image
                    className={styles.homepageImg}
                    height="auto"
                    style={{ height: '100%', width: '85px' }}
                    alt="Map icon"
                    src={MapIcon}
                    />
                </Suspense>
                </div>
                <div className={styles.contentsection}>
                <h5>National Reach</h5>
                <p>
                    Local employees, in communities near you are ready to
                    guide you every step of the way
                </p>
                </div>
            </div>
            </a>
        </Col>

        <Col xs={12} md={3} sm={6}>
            <a
            href="https://www.sunwestmortgage.com/why-sunwest"
            target="_blank"
            rel="noopener"
            style={{ textDecoration: 'none' }}
            >
            <div className={styles.homePageCards}>
                <div className={styles.imgsec}>
                <Suspense fallback={<div>Loading...</div>}>
                    <Image
                    className={styles.homepageImg}
                    height="auto"
                    alt="card-logo"
                    style={{ height: '100%', width: '85px' }}
                    src={RatingIcon}
                    />
                </Suspense>
                </div>
                <div className={styles.contentsection}>
                <h5>Customer Rating of 4.9 stars</h5>
                <p>
                    We&#39;re committed to providing our customers with the
                    best service{' '}
                </p>
                </div>
            </div>
            </a>
        </Col>
        </Row>
    </Container>
  )
}
