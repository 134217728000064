/* eslint-disable react/jsx-no-target-blank */
/*
 * Footer used across all the pages
 */
import React, { Suspense, useEffect, useState, useRef, } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Image from 'react-bootstrap/Image';
import Container from 'react-bootstrap/Container';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import styled from 'styled-components';
import { lazy } from '@loadable/component';
import styles from './footerNew.module.css';
import axios from 'axios';
import parser from 'html-react-parser';
import PingIcon from '../../Images/footer/pingIcon.png';
import MiniPhone from '../../Images/footer/miniPhone.png';
import MiniMail from '../../Images/footer/miniMail.png';
import FbIcon from '../../Images/footer/fbIcon.png';
import InstragramIcon from '../../Images/footer/InstaIcon.png';
import YTIcon from '../../Images/footer/youtubeIcon.png';
import LinkedIn from '../../Images/footer/inIcon.png';
import Twitter from '../../Images/footer/twitterIcon.png';

// const Image = lazy(() => import('react-bootstrap/Image'));

const FooterWrapper = styled.div`
a {
  text-decoration: none !important;
}
a:hover {
  text-decoration: underline !important;
}
@media (min-width:1380px){
  .container{
  max-width: 100%;
padding: 0 50px;
  }
  .awardsSecondCol.col-md-5.col-sm-12.col-12 {
    flex: 28%;
    max-width: 28%;
}
`;

const Footer = (props) => {
  const ref = useRef();
  const date = new Date();
  const currentYear = date.getFullYear();
  const [isIntersecting, setIntersecting] = useState(false);
  const [footerInfoData, setFooterInfoData] = useState();

  useEffect(() => {
    
    const fetchSWMCFooterInfo = async () => {
      try {
        await axios
          .get(process.env.REACT_APP_SWMC_FOOTET_INFO)
          .then((response) => {
            //console.log(response.data);
            setFooterInfoData(response.data);
            const observer = new IntersectionObserver(([entry]) => {
              if (entry.isIntersecting) {
                setIntersecting(entry.isIntersecting);
                observer.disconnect();
              }
            });
            observer.observe(ref.current);
            // Remove the observer as soon as the component is unmounted
            return () => {
              observer.disconnect();
            };
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (e) {
        console.log(e);
      }
    };

    fetchSWMCFooterInfo();
  }, []);
 
  return (
    
    <FooterWrapper>
      <div>
        <footer>
        {footerInfoData ?
        <>
          <Container>
            <Row>
              <Col lg={6} md={6} sm={12} xs={12} className={styles.desctypo}>
                <div className={styles.logosize}>
                  <Suspense fallback={<div>Loading...</div>}>
                    <Image
                      src="https://resources.swmc.com/swmc-images/Footer/logo_smls_header.png"
                      alt="SunWest Logo"
                      className={styles.footer_logo}
                    />
                  </Suspense>
                </div>
                <br />
                <div className={styles.footer_desc_text}>
                  <p>
                    {' '}
                    <b>
                      {' '}
                      For licensing information, go to:{' '}
                      <OverlayTrigger
                        overlay={
                          <Tooltip id="tooltip-disabled">
                            NMLS Consumer Access
                          </Tooltip>
                        }
                      >
                        <a
                          href="http://www.nmlsconsumeraccess.org/EntityDetails.aspx/company/3277"
                          target="_blank"
                          rel="noopener"
                        >
                          <u>www.nmlsconsumeraccess.org</u>
                        </a>
                      </OverlayTrigger>
                      {'. '}
                      <br />
                      Visit{' '}
                      <OverlayTrigger
                        overlay={
                          <Tooltip id="tooltip-disabled">
                            Click here for full list of license information of
                            Sun West Mortgage Company, Inc
                          </Tooltip>
                        }
                      >
                        <a
                          href="https://www.swmc.com/disclaimer"
                          target="_blank"
                          rel="noopener"
                        >
                          <u>https://www.swmc.com/disclaimer</u>
                        </a>
                      </OverlayTrigger>{' '}
                      for the full list of license information.
                    </b>
                    <br /> <br />
                    Please{' '}
                    <OverlayTrigger
                      overlay={
                        <Tooltip id="tooltip-default">
                          View Texas Complaint Notice and Servicing Disclosure
                        </Tooltip>
                      }
                    >
                      <a
                        href="https://www.sunwestmortgage.com/TXdis"
                        target="_blank"
                        rel="noopener"
                      >
                        <b>
                          <u>Click Here</u>
                        </b>
                      </a>
                    </OverlayTrigger>{' '}
                    to view Texas Complaint Notice and Servicing Disclosure.
                  </p>
                </div>
              </Col>
              <Col
                md={{ span: 6, offset: 0 }}
                lg={6}
                sm={12}
                xs={12}
                className={styles.contactus}
              >
                <div className={styles.contact_block}>
                  <h4>Contact Us</h4>

                  <div className={styles.ul_footer}>
                    <div style={{ display: 'flex' }}>
                      <Suspense fallback={<div>Loading...</div>}>
                        <Image
                          style={{
                            width: '15px',
                            height: '15px',
                            marginTop: '4px',
                          }}
                          alt="Location ping icon"
                          src={PingIcon}
                        />
                      </Suspense>
                      <p>
                      <a
                          style={{ color: '#fff' }}
                          target="_blank"
                          href={footerInfoData.contactUs.address.googleMapsUrl}
                          rel="noopener"
                        >
                          {footerInfoData.contactUs.address.fullAddress}
                        </a>
                        {/* <a
                          style={{ color: '#fff' }}
                          target="_blank"
                          href="http://maps.google.com/?q=6131 Orangethorpe Ave Suite 500 Buena Park, CA 90620"
                          rel="noopener"
                        >
                         18303 GRIDLEY RD., CERRITOS, CA 90703
                        </a> */}
                      </p>
                    </div>
                    <div className={styles.contactOptions}>
                      <div style={{ display: 'flex' }}>
                        <Suspense fallback={<div>Loading...</div>}>
                          <Image
                            style={{
                              width: '15px',
                              height: '15px',
                              marginTop: '4px',
                            }}
                            alt="phone icon"
                            src={MiniPhone}
                          />
                        </Suspense>
                        <p>
                          <a href="tel:+18004537884" style={{ color: '#fff' }}>
                            (800) 453-7884
                          </a>
                        </p>
                      </div>
                      <div className={styles.email}>
                        <Suspense fallback={<div>Loading...</div>}>
                          <Image
                            style={{
                              width: '15px',
                              height: '15px',
                              marginTop: '4px',
                            }}
                            alt="logo-image"
                            src={MiniMail}
                          />
                        </Suspense>
                        <p>
                          <a
                            href="mailto:inquiry@swmc.com"
                            style={{ color: '#fff' }}
                          >
                            inquiry@swmc.com
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className={styles.social_footer}>
                    <a
                      href="https://www.facebook.com/SunWestMortgage"
                      target="_blank"
                      rel="noopener"
                      title="Like us on Facebook!"
                    >
                      <Suspense fallback={<div>Loading...</div>}>
                        <Image
                          style={{
                            height: '30px',
                            width: '30px',
                            margin: '10px',
                          }}
                          src={FbIcon}
                        />
                      </Suspense>
                    </a>
                    <a
                      href="https://www.linkedin.com/company/sun-west-mortgage-company/about/"
                      target="_blank"
                      rel="noopener"
                      title="Follow us on LinkedIn!"
                    >
                      <Suspense fallback={<div>Loading...</div>}>
                        <Image
                          style={{
                            height: '30px',
                            width: '30px',
                            margin: '10px',
                          }}
                          src={LinkedIn}
                        />
                      </Suspense>
                    </a>
                    <a
                      href=" https://www.youtube.com/user/SunWestMortgageCo"
                      target="_blank"
                      rel="noopener"
                      title="Subscribe on YouTube!"
                    >
                      <Suspense fallback={<div>Loading...</div>}>
                        <Image
                          style={{
                            height: '30px',
                            width: '30px',
                            margin: '10px',
                          }}
                          src={YTIcon}
                        />
                      </Suspense>
                    </a>
                    <a
                      href="https://www.instagram.com/sunwestmortgage.inc/"
                      target="_blank"
                      rel="noopener"
                      title="Follow us on Instagram!"
                    >
                      <Suspense fallback={<div>Loading...</div>}>
                        <Image
                          style={{
                            height: '33px',
                            width: '33px',
                            margin: '10px',
                          }}
                          src={InstragramIcon}
                        />
                      </Suspense>
                    </a>
                    <a
                      href="https://www.twitter.com/SunWestMortgage"
                      target="_blank"
                      rel="noopener"
                      title="Follow us on Twitter!"
                    >
                      <Suspense fallback={<div>Loading...</div>}>
                        <Image
                          style={{
                            height: '33px',
                            width: '33px',
                            margin: '10px',
                          }}
                          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAD4AAAA9CAYAAAD1VdrqAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAPmSURBVHgB7ZqBddsgEEDPfR3AG0Qb1BtUnSDZIMkEdieQOoG9QZIJ3EygdAKnE+BOYG9wParjcVZlCxCSnPf47/FIMJw44E5wAiCRSCQSicRHZQYDg4hzyu4ofaGUUVpwLtlzeqf0S+ez2WwPAzKI4qxsTmnJueEIVskjl805LTg3vFF6pgF4gY8AKb2idMAanVeU7indOLTNKD1Q2qJF6fZwrVDncu6kUbjgmQ+VZwZBiQHI4JqgDpWxFD4jvxAroISp0QqKZbkbckZ4BZjZ38YeXJ+OzFlZzRpGQj9LDPT4ygulCxgZsfR3MCb0wM1USos+FKOuNva0/+wMJkb4lxUMiXAwV/FqYT+j+G2S+bT9DH7opZ1RemhuKdnRLC+0/U1tfoInDbkv8rn095F+f6Q/K+7bI8SGZ1vzdKHOGs/jPSuNN0dxoV7FdXKIjVaYhWdwuaOK691TuuF0y2UKPV5BwoaLjnp516QEIWa7cqx7aCqJ9R5e4+SFqd6zZ/2Knxvv3Y7WkzsdFoSSVUvnNKuO9qXvDIo+LiEWosM+y3TdVBIdvLBQ2mtzwrIP6LAqfYR6C8RTe89FeX7O3qXSGLBkzXKHGJCgBTo4mDNtne1dlCkM3COIVdZ59ncRdsfCbiEAF3sX9hmsNMt86NPXprAlC/sKgTjY+wEj7AaFGfWP2KA9DAQvH+y2d80CeoL2tdtplp9gBPTWkrJvUAcYn4y9U/kbZd+52qhxtVEU1/Ae+wfUe/2tKN9AHVFd4dCnLB9i2HhDXtD73VG2MZ3OTYzLjP/hPIOeoI23a9bG3tkU9MlK/15h+LbTtNtDX4TD6B3pQBGjwwj7+Rb5RSxHaQT23gqiPXQU/H/X+93b2WHMnZsUGLoE0cbonhrlzy32noXYO/sJTbyQmHBwIbNQnusQnr7fF6LcOCnnPTt6niCdwMCTDzocOrA+C7TZe+Fj78JEMogJ2ghM7ljfKK2wY9aEvW8b5ZXLLKJDWCwYsfxcojDeh44+9o4OYbFeuMw62mOsQn/npLits72LQY4/2+Ihredr8bu01ww8Ee2NDCUGQ7Nu6Y8KfZ5v587Z45xtUmGPDQTLVxfSnahrorDjHHDQ7rdLmAi0znO0L7XmwbuplMfAgGSsh0/1fXxzydmN1Ql5I0Lh8DcizEBPdyOi0akCLSVEhAe3RHuTarJv8q3g6R0Vnd9jv6BCU2EtM0owZBDw9IqWWZZOg4D2ileFp3fl4n0WYga70qkVgDqAmItiHWl55/zIZdpWM07Nm40bis68wgCMcZc1g/oeaw72HmvWqLaHekBM/srhqEQikUgkEon/+AtkijqmmV3npwAAAABJRU5ErkJggg=="
                        />
                      </Suspense>
                    </a>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <p
                  style={{
                    textAlign: 'center',
                    marginTop: '1rem',
                    marginBottom: '0rem',
                  }}
                >
                  Although Sun West Mortgage is approved to conduct business in
                  the state of New York, this website has not yet been approved
                  by the State of New York Department of Financial Services. For
                  properties located in the State of New York, this website
                  cannot be used to upload an application, please visit{' '}
                  <a
                    href="https://www.swmc.com"
                    target="_blank"
                    rel="noopener"
                    style={{ color: 'white', textDecoration: 'underline' }}
                  >
                    <u>SWMC.com</u>
                  </a>{' '}
                  to upload an application.
                </p>
              </Col>
            </Row>
            <hr style={{ borderTop: '1px solid #fff' }} />

            <Row className={styles.awardrow}>
              <Col md={12}>
                <Row>
                  <Col xl={7} md={12}>
                    <Row className={styles.leftRow}>
                      <Col
                        md={3}
                        sm={6}
                        xs={6}
                        lg={3}
                        xl={3}
                        className={styles.topIconDiv}
                      >
                        <Suspense fallback={<div>Loading...</div>}>
                          <Image
                            src="https://resources.swmc.com/swmc-images/Footer/swmc/Top-50-Logo-blue.png"
                            className={styles.topIcons}
                            alt="Mortgage Executive Top 50 logo"
                            title="Mortgage Executive Top 50"
                          />
                        </Suspense>
                      </Col>

                      <Col md={3} sm={6} xs={6} className={styles.bbbMemberDiv}>
                        <Suspense fallback={<div>Loading...</div>}>
                          <Image
                            src="https://resources.swmc.com/swmc-images/Footer/bbb-white.png"
                            className={styles.bbbIcon}
                            alt="bbb"
                          />
                        </Suspense>

                        <Suspense fallback={<div>Loading...</div>}>
                          <Image
                            src="https://resources.swmc.com/swmc-images/Footer/swmc/Bcawidgets.png"
                            className={styles.memberIcon}
                            alt="alliance"
                          />
                        </Suspense>
                      </Col>
                      <Col md={3} sm={12} className={styles.ehoBirdeye}>
                        <Suspense fallback={<div>Loading...</div>}>
                          <Image
                            fluid
                            src="https://resources.swmc.com/swmc-images/Footer/equal-housing.png"
                            alt="SunWest Logo"
                            title="Equal Housing Opportunity"
                            className={styles.homicon}
                          />
                        </Suspense>
                      </Col>
                    </Row>
                  </Col>

                  <Col
                    xl={5}
                    md={12}
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                    }}
                  >
                    <Row className={styles.tacaDiv}>
                      <a
                        href="https://swmc.com/autismheroes/"
                        target="_blank"
                        rel="noopener"
                      >
                        <Image
                          src="https://resources.swmc.com/swmc-images/Footer/TACA-LOGO.jpg"
                          title="Autism Heroes"
                          alt="Autism Heroes Logo"
                          className={styles.Taca}
                        />
                      </a>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>

          <Container className={styles.footer_link_banner}>
            <Row className={styles.footcont}>
              <Col
                md={4}
                sm={12}
                className={`${styles.padleft0} ${styles.copyrighttxt}`}
              >
                <p className={`${styles.ul_footer} ${styles.footer_list}`}>
                  &copy; {currentYear} Sun West Mortgage Company, Inc. All
                  Rights Reserved
                </p>
              </Col>
              <Col
                md={8}
                sm={12}
                className={`${styles.padleft0} ${styles.padright0} ${styles.dislinks}`}
              >
                <ul
                  className={`${styles.ul_footer} ${styles.footer_list} ${styles.linklist}`}
                >
                  <li>
                    <a
                      href="https://www.sunwestmortgage.com/contact-us"
                      target="_blank"
                      rel="noopener"
                      style={{ color: '#fff' }}
                      title="Contact SWMC"
                    >
                      Contact Us
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://askangel.ai/"
                      target="_blank"
                      rel="noopener"
                      style={{ color: '#fff' }}
                    >
                      Ask Angel Ai
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.sunwestmortgage.com/borrower-concerns-complaints"
                      target="_blank"
                      rel="noopener"
                      style={{ color: '#fff' }}
                      title="Borrower Concerns and Complaints Form"
                    >
                      Borrower Concerns & Complaints
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.sunwestmortgage.com/privacy-policy"
                      target="_blank"
                      rel="noopener"
                      style={{ color: '#fff' }}
                      title="Our Privacy Policy"
                    >
                      Privacy Policy
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.sunwestmortgage.com/disclaimer"
                      target="_blank"
                      rel="noopener"
                      style={{ color: '#fff' }}
                      title="List of license information of Sun West Mortgage Company, Inc."
                    >
                      Licenses
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.sunwestmortgage.com/swmc-site-map"
                      target="_blank"
                      rel="noopener"
                      style={{ color: '#fff' }}
                      title="Our Site Map Page"
                    >
                      Site Map
                    </a>
                  </li>
                </ul>
              </Col>
            </Row>
          </Container>
          </>
              : ""}
        </footer>
      </div>
    </FooterWrapper>
  );
};

export default Footer;
